import { LoadingArea } from '@consigli/facade';
import {
  useProjectId,
  useLazyGetCeilingGridFilesQuery,
  useCeilingGridId,
  useCeilingGridFiles,
} from '@consigli/hooks';
import { convertFileToDocument } from '@consigli/types';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { Breadcrumb } from '@/components/layouts/breadcrumb/breadcrumb';
import { WorkspaceLayout } from '@/components/layouts/workspace-layout/workspace-layout';
import { WorkspaceProvider } from '@/contexts/use-workspace-context';
import { Documents } from '@/organisms/document-list/documents';

export const CeilingGridFileList: FC = () => {
  const projectId = useProjectId();
  const ceilingGridId = useCeilingGridId();

  const { t } = useTranslation();

  const [page, setPage] = useState(1);

  const { files, totalCount, isLoading, isFetching } = useCeilingGridFiles({
    projectId,
    ceilingGridId,
    page: page,
  });

  const [fetchAllFiles, { isFetching: isFetchingAllFiles }] = useLazyGetCeilingGridFilesQuery();

  const handleFetchAllFiles = useCallback(async () => {
    const ceilingGridFilesResponse = await fetchAllFiles({
      projectId,
      ceilingGridId,
      page: 'all',
    }).unwrap();
    return ceilingGridFilesResponse.results.map((file) => convertFileToDocument(file));
  }, [fetchAllFiles, projectId, ceilingGridId]);

  return (
    <>
      <Breadcrumb label={t('optimization-tabs.input')} />
      <WorkspaceProvider>
        <WorkspaceLayout>
          {isLoading ? (
            <LoadingArea title={t('document-list.loading')} />
          ) : files.length > 0 ? (
            <Documents
              files={files.map((file) => convertFileToDocument(file))}
              headerTitle={t('document-list.documents')}
              paginatedCount={totalCount}
              totalCount={totalCount}
              page={page}
              setPage={setPage}
              isFetching={isFetching}
              fetchAllFiles={handleFetchAllFiles}
              isFetchingAllFiles={isFetchingAllFiles}
              packageId={ceilingGridId}
            />
          ) : (
            <div className="flex justify-center items-center w-full h-full text-[24px]">
              {t('document-list.no-documents')}
            </div>
          )}
        </WorkspaceLayout>
      </WorkspaceProvider>
    </>
  );
};
