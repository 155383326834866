import {
  Pagination,
  Spinner,
  Table,
  TableBody,
  TableDataCell,
  TableHead,
  TableHeaderCell,
  TableRow,
} from '@consigli/facade';
import { useInvites, useMyUser } from '@consigli/hooks';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

export const UserManagementInviteList: FC = () => {
  const pageSize = 8;
  const { user } = useMyUser();
  const { t } = useTranslation();
  const [page, setPage] = useState(1);

  const { invites, paginatedCount, isFetching } = useInvites({
    page: page,
    pageSize: pageSize,
    invitedByEmail: !user.isSuperuser ? user.email : '',
  });

  const formatDate = useCallback((isoDateString: string): string => {
    const date = new Date(isoDateString);
    return date.toLocaleDateString('nb-NO');
  }, []);

  const handlePageChange = useCallback(
    (page: number) => {
      setPage(page);
    },
    [setPage],
  );

  return (
    <>
      <div className="max-h-[60vh] overflow-y-scroll">
        <Table>
          <TableHead className="sticky top-0 bg-day-light-4">
            <TableRow>
              <TableHeaderCell className="pr-16 text-sm max-w-[25vw]">
                {t('user-management.recipient')}
              </TableHeaderCell>
              <TableHeaderCell className="pr-16 text-sm max-w-[25vw]">
                {t('user-management.status')}
              </TableHeaderCell>
              <TableHeaderCell className="text-sm max-w-[50vw]">
                {t('user-management.expires-at')}
              </TableHeaderCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {isFetching ? (
              <TableRow>
                <TableDataCell colSpan={3} className="text-center py-8">
                  <div className="flex justify-center items-center w-full">
                    <Spinner />
                  </div>
                </TableDataCell>
              </TableRow>
            ) : (
              invites.map((invite) => (
                <TableRow key={invite.id}>
                  <TableDataCell className="py-3 pr-16 text-sm max-w-[25vw] min-h-10 truncate">
                    {invite.recipient}
                  </TableDataCell>
                  <TableDataCell className="py-3 pr-16 text-sm max-w-[25vw] min-h-10 truncate">
                    {t(`user-management.invite-status.${invite.status.toLowerCase()}`)}
                  </TableDataCell>
                  <TableDataCell className="py-3 pr-16 text-sm max-w-[25vw] min-h-10 truncate">
                    {formatDate(invite.expiresAt)}
                  </TableDataCell>
                </TableRow>
              ))
            )}
          </TableBody>
        </Table>
      </div>
      <div className="mt-6 flex justify-between w-full items-center">
        <div className="flex items-center">
          <p className="font-bold mr-4 text-xs">
            {paginatedCount} {t('user-management.invites')}
          </p>
          <Pagination
            currentPage={page}
            totalCount={paginatedCount || 0}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
      </div>
    </>
  );
};
