import {
  createContext,
  Dispatch,
  FC,
  PropsWithChildren,
  SetStateAction,
  useContext,
  useState,
} from 'react';

type DocumentsContext = {
  searchTerm: string;
  setSearchTerm: Dispatch<SetStateAction<string>>;
};

export const DocumentsContext = createContext<DocumentsContext | null>(null);

export const useDocumentsContext = () => {
  const ctx = useContext(DocumentsContext);

  if (!ctx) {
    throw new Error('Missing Documents Provider in tree');
  }

  return ctx;
};

export const DocumentsProvider: FC<PropsWithChildren> = ({ children }) => {
  const [searchTerm, setSearchTerm] = useState<string>('');

  return (
    <DocumentsContext.Provider
      value={{
        searchTerm,
        setSearchTerm,
      }}
    >
      {children}
    </DocumentsContext.Provider>
  );
};
