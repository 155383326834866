import { CustomSelect, SelectOption } from '@consigli/facade';
import { Language } from '@consigli/types';
import { FieldValues, useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

export const SelectLanguage = () => {
  const { t } = useTranslation();

  const { control } = useFormContext<FieldValues>();
  const { field: languageField, fieldState: languageFieldState } = useController({
    name: 'language',
    control,
    rules: {
      validate: (value) => !!value || `${t('createproject.invalid-project-language-empty')}*`,
    },
  });
  const languageOptions: SelectOption[] = [
    {
      label: t('language.nb'),
      value: Language.NB,
    },
    {
      label: t('language.nn'),
      value: Language.NN,
    },
    {
      label: t('language.en'),
      value: Language.EN,
    },
    {
      label: t('language.ja'),
      value: Language.JA,
    },
    {
      label: t('language.sv'),
      value: Language.SV,
    },
    {
      label: t('language.vi'),
      value: Language.VI,
    },
  ];
  return (
    <span className="mb-8 mt-4">
      <CustomSelect
        onChange={(selectedOptions: SelectOption[]) => {
          languageField.onChange(selectedOptions.length > 0 ? selectedOptions[0].value : null);
        }}
        options={languageOptions}
        placeholder={t('language.select')}
        value={
          languageField.value
            ? [languageOptions.find((option) => option.value === languageField.value)]
            : []
        }
        noOptionsMessage={t('custom-select.no-options')}
      />
      {languageFieldState.invalid && (
        <div className="text-red-700 text-sm">{languageFieldState.error?.message as string}</div>
      )}
    </span>
  );
};
