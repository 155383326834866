import { Button } from '@consigli/facade';
import {
  useLazyGetPackagesByServiceIdQuery,
  useLazyGetLayoutOptimizationsQuery,
  useLazyGetStructuralEngineeringsQuery,
  useLazyGetCeilingGridsQuery,
} from '@consigli/hooks';
import { CeilingType, LayoutType, ServiceId, StructuralType } from '@consigli/types';
import clsx from 'clsx';
import { useCallback, useMemo, type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { FaPlus } from 'react-icons/fa6';
import { HiOutlineInformationCircle } from 'react-icons/hi';
import { LuBadgeCheck } from 'react-icons/lu';
import { useNavigate } from 'react-router';
import { Tooltip } from 'react-tooltip';

import { Route } from '@/routes';

interface ServiceCardProps {
  id: string | undefined;
  title: string;
  icon: string;
  active?: boolean;
  tooltip?: string;
  serviceRoute: string;
  newServiceRoute: string;
  serviceId: ServiceId | LayoutType | StructuralType | CeilingType;
  projectId: number;
}

export const ServiceCard: FC<ServiceCardProps> = ({
  id,
  title,
  icon,
  active,
  tooltip,
  newServiceRoute,
  serviceRoute,
  serviceId,
  projectId,
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [getPackages] = useLazyGetPackagesByServiceIdQuery();
  const [getLayouts] = useLazyGetLayoutOptimizationsQuery();
  const [getStructural] = useLazyGetStructuralEngineeringsQuery();
  const [getCeilingGrids] = useLazyGetCeilingGridsQuery();

  const titleText = useMemo(() => {
    if (serviceRoute === Route.REPORTS) {
      return t('service-tooltips.title-text-reports');
    }
    if (
      serviceRoute === Route.TENDER_DOCUMENTS ||
      serviceRoute === Route.MANAGEMENT_OPERATIONS_MAINTENANCE ||
      serviceRoute === Route.OFFER ||
      serviceRoute === Route.TENANT_SPECIFICATION ||
      serviceRoute === Route.DUE_DILIGENCE
    ) {
      return t('service-tooltips.title-text-insight', { serviceName: title });
    }
    return t('service-tooltips.title-text-optimization', { serviceName: title });
  }, [serviceRoute, t, title]);

  const ServiceTooltip = () => (
    <Tooltip
      id={id}
      place="top-end"
      opacity={100}
      classNameArrow="hidden"
      // There is some issue with the tooltip library and Tailwind v4, we have to add ! to override the default styles.
      className="!bg-day-light-5 !p-4 !h-auto !w-72 !text-day-neutral-subtle !rounded-tl-[24px] !rounded-bl-[24px] !rounded-tr-[24px] !shadow-[0_1px_2px_0_rgba(0,0,0,0.06),0_1px_3px_0_rgba(0,0,0,0.10)] !z-10"
    >
      <div className="text-xs text-left py-4">{tooltip}</div>
      <div className="text-xs font-semibold text-left">{titleText}</div>
    </Tooltip>
  );

  const redirectUser = useCallback(async () => {
    if (active) {
      //Determine what lazy query to use for fetching packages based on whether the service is a layout or not
      let packages = [];
      if (Object.values(ServiceId).includes(serviceId)) {
        packages = await getPackages({
          projectId,
          serviceId: serviceId as ServiceId,
        }).unwrap();
        //redirect to the dashboard of the last created package, ordered by creation date from backend
        return `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceRoute}/${Route.PACKAGES}/${packages[0].id}/${Route.DASHBOARD}`;
      } else if (serviceId === StructuralType.STRUCTURAL) {
        packages = await getStructural({
          projectId,
          type: serviceId as StructuralType,
        }).unwrap();
        //redirect to the dashboard of the last created package, ordered by creation date from backend
        return `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceRoute}/${Route.PACKAGES}/${packages[0].id}/${Route.DASHBOARD}`;
      } else if (serviceId === CeilingType.CEILING_GRID) {
        const response = await getCeilingGrids({
          projectId,
          type: serviceId as CeilingType,
          page: 'all',
        }).unwrap();
        packages = response.results;
        //redirect to the dashboard of the last created package, ordered by creation date from backend
        return `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceRoute}/${Route.PACKAGES}/${packages[0].id}/${Route.DASHBOARD}`;
      } else {
        packages = await getLayouts({
          projectId,
          type: serviceId as LayoutType,
        }).unwrap();
        return `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceRoute}/${Route.PACKAGES}/${packages[0].id}/${Route.DASHBOARD}`;
      }
    } else return newServiceRoute;
  }, [
    active,
    getCeilingGrids,
    getLayouts,
    getPackages,
    getStructural,
    newServiceRoute,
    projectId,
    serviceId,
    serviceRoute,
  ]);

  const handleNavigate = async () => {
    navigate(await redirectUser());
  };

  return (
    <div className="relative group">
      <div
        className={clsx(
          'flex flex-col items-center bg-day-light-4 gap-4 rounded-[24px] shadow-[0_1px_2px_0_rgba(0,0,0,0.06),0_1px_3px_0_rgba(0,0,0,0.10)] focus:outline-hidden focus:ring-light-grey border-4 border-day-light-4 cursor-pointer',
        )}
        onClick={handleNavigate}
        //Makes the non-interactive element <div> behave like a button, but without nested button warnings
        tabIndex={0}
        onKeyDown={(event) => {
          if (event.key === 'Enter') {
            handleNavigate();
          }
        }}
        role="button"
      >
        <div className="relative group overflow-hidden rounded-t-[24px] w-full 2xl:max-w-[335px] ">
          <div
            className={clsx(
              active ? 'top-[calc(0%-80px)]' : 'top-[calc(0%-150px)]',
              'overflow-hidden absolute w-full h-full  group-hover:top-0 transition-all duration-500 ease-in-out rounded-tl-[24px] rounded-tr-[24px] group-hover:rounded-b-[24px] bg-day-light-3',
            )}
          />
          <div className="relative flex flex-col justify-evenly items-center text-center w-full">
            <div className="w-full flex justify-center items-center px-6 pt-6">
              <img
                src={icon}
                alt="services"
                className="transition-transform duration-200 ease-in-out h-48 group-hover:scale-125"
              />
            </div>
            <div className="flex flex-row justify-between items-center w-full p-6 h-[75px]">
              {active && <LuBadgeCheck size={'1.5em'} color="#00A16E" />}
              <div
                className={clsx(
                  !active && 'text-left',
                  'text-base w-full px-2 flex-1 pr-4 font-semibold text-day-neutral-dark',
                )}
                title={title}
              >
                {title}
              </div>
              <HiOutlineInformationCircle size={'1.5em'} data-tooltip-id={id} color={'#334155'} />
            </div>
          </div>
          {active ? null : (
            <div className="flex flex-col justify-evenly items-center w-full pb-6 px-6">
              <Button type="button" secondary rounded className="w-full z-10" icon={FaPlus}>
                {t('service-list.add')}
              </Button>
            </div>
          )}
        </div>
      </div>
      {tooltip && <ServiceTooltip />}
    </div>
  );
};
