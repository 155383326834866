import { FileUpload, LoadingArea } from '@consigli/facade';
import { usePackageId, useProjectId, useServiceName } from '@consigli/hooks';
import { PipelineName } from '@consigli/types';
import { FC, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { toast } from 'react-toastify';

import { SupportedFileFormats } from '@/atoms/supported-file-formats';
import { Breadcrumb } from '@/components/layouts/breadcrumb/breadcrumb';
import { Nav } from '@/layouts/nav';
import { Route } from '@/routes';

import { WizardAnalyzeButton } from '../components/wizard-analyze-button';
import { useCreateInsightPackage } from '../hooks/useCreateInsightPackage';
import { useUploadProgress } from '../hooks/useUploadProgress';

export const InsightAddMoreDocuments: FC = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const projectId = useProjectId();
  const packageId = usePackageId();
  const serviceName = useServiceName();
  const [localFiles, setLocalFiles] = useState<File[]>([]);
  const [showMessagePanel, setShowMessagePanel] = useState(true);
  const [isProcessing, setIsProcessing] = useState(false);
  const [targetCounter, setTargetCounter] = useState(0);
  const [currentCounter, setCurrentCounter] = useState(0);
  const { uploadFiles, startAnalysis } = useCreateInsightPackage({
    setCurrentCounter,
    setTargetCounter,
  });
  const { isUploading, progress } = useUploadProgress(currentCounter, targetCounter);

  const onAnalyze = useCallback(async () => {
    // step 1, upload files from local state of files, , then initiate analysis phase
    await uploadFiles(localFiles, packageId);
    setIsProcessing(true);
    try {
      // step 2, start pipeline
      await startAnalysis(packageId, PipelineName.VALIDATION_TO_POST_PARSING);
      toast.success(t('package-creation.success-analyzed'));
    } catch (error) {
      toast.error(t(`package-creation.failed-analyzed`));
    } finally {
      setIsProcessing(false);
    }
  }, [localFiles, packageId, startAnalysis, t, uploadFiles]);

  return (
    <div className="w-full flex flex-col min-h-[calc(100vh-theme(space.14))] bg-day-light-4">
      <Breadcrumb label={t('package-creation.upload')} />
      <Nav
        projectId={projectId}
        handleClick={() =>
          navigate(
            `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${packageId}/${Route.CATEGORIZATION}`,
          )
        }
      />
      <div className="flex flex-col flex-1 items-center px-4 py-12 w-full sm:w-[30rem] mx-auto">
        <div className="mb-10 font-semibold text-2xl text-day-neutral-dark">
          {t('document-upload.upload-button')}
        </div>
        <>
          {isUploading || isProcessing ? (
            <LoadingArea
              className="mt-20"
              title={t('document-upload.uploading')}
              message={progress < 1 ? t('document-upload.initialising') : `${progress}%`}
            />
          ) : (
            <FileUpload
              files={localFiles}
              setFiles={setLocalFiles}
              dropAreaLabel={t('document-upload.drag-n-drop-here')}
              dropAreaClassName="mt-4 h-48 w-full"
              fileButtonLabel={t('document-upload.browse-files')}
              folderButtonLabel={t('document-upload.browse-folders')}
              heading={t('package-creation.upload-files')}
              uploadListLabel={t('document-upload.files')}
              zeroByteFilesMessage={t('document-upload.file-zero-bytes')}
              infoMessage={<SupportedFileFormats />}
              showMessagePanel={showMessagePanel}
              setShowMessagePanel={setShowMessagePanel}
            >
              <WizardAnalyzeButton
                localFiles={localFiles}
                onAnalyze={onAnalyze}
              ></WizardAnalyzeButton>
            </FileUpload>
          )}
        </>
      </div>
    </div>
  );
};
