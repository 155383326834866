import { Button, Spinner } from '@consigli/facade';
import { useDownloadFile } from '@consigli/hooks';
import { Document } from '@consigli/types';
import clsx from 'clsx';
import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineCloudDownload } from 'react-icons/hi';

export const DownloadFileButton: FC<{
  document: Document;
  className?: string;
  label?: string;
  size?: string;
}> = ({ document, className, label = '', size = '20' }) => {
  const { t } = useTranslation();

  const { downloadFile, isDownloading } = useDownloadFile(document);

  return (
    <Button
      onClick={(event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        downloadFile();
      }}
      className={clsx('p-2 hover:bg-gray-100 font-normal', className)}
      disabled={isDownloading}
      title={t('document-list.download')}
      icon={isDownloading ? () => <Spinner size="xsmall" /> : HiOutlineCloudDownload}
      iconSize={size}
      tertiary
    >
      {label}
    </Button>
  );
};
