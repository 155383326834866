import { ExcelExport, Finding, FindingComment, FindingCommentType } from '@consigli/types';
import { TFunction } from 'i18next';

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  const hours = String(date.getHours()).padStart(2, '0');
  const minutes = String(date.getMinutes()).padStart(2, '0');
  const day = date.getDate();
  // getMonth is 0-indexed, so have to + 1
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const year = String(date.getFullYear()).substring(2);

  return `${hours}:${minutes}, ${day}/${month}/${year}`;
};

const formatComments = (comments: FindingComment[], t: TFunction) => {
  return comments
    .map((comment) => {
      const authorName =
        comment.type === FindingCommentType.SYSTEM_COMMENT_FORWARDED
          ? 'Consigli'
          : comment.authorName;

      const commentText =
        comment.type !== FindingCommentType.USER_COMMENT
          ? t(`riskassessment.comment-codes.${comment.type.toLowerCase()}`, {
              commentText: comment.text,
            })
          : comment.text;

      const createdAt = comment.createdAt ? formatDate(comment.createdAt) : '';

      return `- ${authorName}: ${commentText} - ${createdAt}`;
    })
    .join('\r\n');
};

export const createFindingsExcelExport = (
  findings: Finding[],
  t: TFunction,
  projectName: string,
): ExcelExport => {
  const title = projectName;
  const headers = [
    'ID',
    t('riskassessment.export.status'),
    t('riskassessment.export.conflict-type'),
    t('riskassessment.export.document'),
    t('riskassessment.export.comment'),
    t('riskassessment.export.page-number'),
    t('riskassessment.export.content'),
    t('riskassessment.export.ref-content'),
    t('riskassessment.export.ref-document'),
    t('riskassessment.export.comments'),
  ];
  const sortedFindings = [...findings].sort((a, b) => a.id - b.id);
  const data = sortedFindings.map((finding) => {
    return [
      finding.id,
      t(`riskassessment.status.${finding.action}`),
      t(`dashboard.conflict-title.${finding.typeOfConflict}`),
      finding.fileName ? finding.fileName : t('riskassessment.project-related-finding'),
      t(finding.messageTemplate, {
        ...finding.messageContext,
      }),
      finding.pageNumber ? finding.pageNumber : '',
      finding.content ? finding.content : '',
      finding.refContent ? finding.refContent : '',
      finding.refFileName ? finding.refFileName : '',
      finding.comments ? formatComments(finding.comments, t) : '',
    ];
  });
  return { title, headers, data };
};
