import clsx from 'clsx';
import { FC, ReactNode } from 'react';

import { WorkspaceTabs } from '@/components/layouts/workspace-layout/workspace-tabs';
import { useViewerContext, ViewerMode } from '@/contexts/use-viewer-context';
import { useWorkspaceContext } from '@/contexts/use-workspace-context';

import { Breadcrumbs } from '../breadcrumb/breadcrumbs';

type WorkspaceLayoutProps = {
  children: ReactNode;
  hideTabs?: boolean;
};

export const WorkspaceLayout: FC<WorkspaceLayoutProps> = ({ children, hideTabs }) => {
  const { mode } = useViewerContext();
  const { isOverlayVisible } = useWorkspaceContext();

  return (
    <main
      className={clsx(
        'flex flex-row w-full flex-grow max-h-[calc(100vh-theme(space.14))] bg-day-light-4 overflow-y-scroll',
      )}
    >
      <div
        className={clsx('flex flex-col grow', mode !== ViewerMode.DocumentFullscreen && 'mx-auto')}
      >
        <div className="flex flex-col">
          <div
            className={clsx(
              'flex items-center justify-center py-8 transition-colors duration-300',
              isOverlayVisible && 'bg-white',
            )}
          >
            <div className="flex flex-row grow max-w-screen-2xl mx-auto px-8">
              <Breadcrumbs />
            </div>
          </div>
          <div className="flex flex-col md:flex-row justify-center items-center w-full">
            <WorkspaceTabs hideTabs={hideTabs} />
          </div>
        </div>
        <div className="w-full h-full">
          <div className="max-w-screen 2xl:max-w-screen-2xl mx-auto h-full">{children}</div>
        </div>
      </div>
    </main>
  );
};
