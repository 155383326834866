import { Button, Search } from '@consigli/facade';
import { useServiceName, useProjectId } from '@consigli/hooks';
import {
  CeilingType,
  Language,
  LayoutType,
  ServiceId,
  ServiceName,
  StructuralType,
} from '@consigli/types';
import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { LiaPlusSquare } from 'react-icons/lia';
import { MdOutlineKeyboardArrowUp, MdOutlineKeyboardArrowDown } from 'react-icons/md';
import { useNavigate } from 'react-router';

import { useWorkspaceContext } from '@/contexts/use-workspace-context';
import { Route } from '@/routes';

export function convertServiceNameToId(serviceName: ServiceName): ServiceId {
  switch (serviceName) {
    case ServiceName.DUE_DILIGENCE:
      return ServiceId.DUE_DILIGENCE;
    case ServiceName.TENANT_REQUIREMENTS:
      return ServiceId.TENANT_REQUIREMENTS;
    case ServiceName.TENDER_DOCUMENTS:
      return ServiceId.TENDER_DOCUMENTS;
    case ServiceName.MANAGEMENT_OPERATIONS_MAINTENANCE:
      return ServiceId.MANAGEMENT_OPERATIONS_MAINTENANCE;
    case ServiceName.TENANT_SPECIFICATION:
      return ServiceId.TENANT_SPECIFICATION;
    case ServiceName.OFFER:
      return ServiceId.OFFER;
    default:
      throw new Error(`Unknown ServiceName: ${serviceName}`);
  }
}

export type PackageType = {
  id: string;
  name: string;
  project: number;
  projectPhase: number;
  externalFamacBuildingId: string | null;
  createdBy: string | null;
  language?: Language | undefined;
};

export type OptimizationType = {
  type: LayoutType;
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  projectId: number;
  data?: { ceilingHeight: string; comments: string; floorHeight: string };
};

export type StructuralEngineeringType = {
  type: StructuralType;
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  projectId: number;
  data?: { description: string };
};

export type CeilingGridType = {
  type: CeilingType;
  id: string;
  name: string;
  createdAt: string;
  updatedAt: string;
  projectId: number;
  errorMessage?: string[] | undefined;
  warningMessage?: string[] | undefined;
  healthCheck?: boolean | null | undefined;
  buildingJson?: string | undefined;
};

type PackageListProps = {
  packages: PackageType[] | OptimizationType[] | StructuralEngineeringType[] | CeilingGridType[];
  id: string;
};
export const PackageList = ({ packages, id }: PackageListProps) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [searchText, setSearchText] = useState('');
  const serviceName = useServiceName();
  const projectId = useProjectId();
  const { isOverlayVisible, setIsOverlayVisible } = useWorkspaceContext();

  const handleSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  }, []);

  const [selectedValue, setSelectedValue] = useState<
    OptimizationType | PackageType | StructuralEngineeringType | CeilingGridType | undefined
  >(undefined);
  useEffect(() => {
    if (id) {
      setSelectedValue(packages.find((item) => item.id === id));
    }
  }, [id, selectedValue, packages]);

  const onOptionChange = useCallback(
    (
      newValue: OptimizationType | PackageType | StructuralEngineeringType | CeilingGridType,
    ): void => {
      if (newValue) {
        setSelectedValue(newValue);
        navigate(
          `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${newValue.id}/${Route.DASHBOARD}`,
        );
      }
    },
    [navigate, projectId, serviceName],
  );
  const filteredPackages = useMemo(() => {
    return packages.filter((pack) => pack.name.toLowerCase().includes(searchText.toLowerCase()));
  }, [packages, searchText]);
  const type = useMemo(() => {
    if (
      serviceName === ServiceName.TENDER_DOCUMENTS ||
      serviceName === ServiceName.OFFER ||
      serviceName === ServiceName.MANAGEMENT_OPERATIONS_MAINTENANCE ||
      serviceName === ServiceName.TENANT_REQUIREMENTS ||
      serviceName === ServiceName.DUE_DILIGENCE
    ) {
      return 'ANALYSIS';
    } else {
      return 'ALTERNATIVE';
    }
  }, [serviceName]);
  return (
    <div className="relative">
      <div className={clsx('flex items-center z-50 relative')}>
        <div className="flex items-center gap-2">
          <div
            className="flex flex-row items-center cursor-pointer"
            onClick={() => setIsOverlayVisible(!isOverlayVisible)}
            aria-hidden
          >
            <div className="mr-2 text-[#67888D] text-lg">
              {type === 'ALTERNATIVE' ? t('project-list.alternatives') : t('project-list.analysis')}
            </div>

            <Button tertiary onClick={() => {}} className="p-1 hover:bg-day-light-3 rounded-full">
              {isOverlayVisible ? (
                <MdOutlineKeyboardArrowUp size={24} className="transition-transform" />
              ) : (
                <MdOutlineKeyboardArrowDown size={24} className="transition-transform" />
              )}
            </Button>
          </div>
          <div
            className="ml-4 max-w-[200px] truncate overflow-hidden text-ellipsis whitespace-nowrap"
            title={selectedValue?.name || ''}
          >
            {selectedValue?.name}
          </div>
        </div>
      </div>

      <div
        className={`
        fixed top-50 left-0 w-screen h-screen bg-black/40 z-30 
        transition-opacity duration-300
        ${isOverlayVisible ? 'opacity-100 pointer-events-auto' : 'opacity-0 pointer-events-none'}
      `}
        onClick={() => setIsOverlayVisible(false)}
        aria-hidden
      />

      <div
        className={`
        fixed top-[145px] left-0 w-screen h-auto max-h-[60vh] bg-white z-30 shadow-lg
        transition-all duration-700 ease-in-out transform-gpu
        ${isOverlayVisible ? 'opacity-100 scale-y-100 pointer-events-auto' : 'opacity-0 scale-y-0 pointer-events-none'}
      `}
        style={{ transformOrigin: 'top center' }}
      >
        <div className="max-w-screen-2xl mx-auto overflow-y-auto min-h-28 max-h-[60vh] pt-4 pb-4 px-2">
          <div className="flex flex-row justify-end items-center w-full pb-4 right-10">
            <Search
              text={searchText}
              onChange={handleSearch}
              placeholder={
                type === 'ALTERNATIVE'
                  ? t('project-list.search-alternatives')
                  : t('project-list.search-analysis')
              }
            />
          </div>
          <div className="flex flex-col flex-wrap gap-x-8 gap-y-4 px-8 h-[250px]">
            {filteredPackages.map((pack) => (
              <div
                key={pack.id}
                className="cursor-pointer transition-colors duration-200 ease-in-out text-[#7588A3] w-fit border-b-[3px] border-b-transparent hover:border-b-[3px] hover:border-day-neutral-dark"
                onClick={() => {
                  onOptionChange(pack);
                  setIsOverlayVisible(false);
                }}
                aria-hidden
              >
                {pack.name}
              </div>
            ))}
          </div>
          <div
            className="flex justify-start items-center mt-6 mb-6 px-4 gap-1 text-base"
            aria-hidden
            onClick={() =>
              navigate(
                `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.CREATE}`,
              )
            }
          >
            <div className="mr-2 text-[#67888D] text-lg">
              {type === 'ALTERNATIVE'
                ? t('project-list.new-alternative')
                : t('project-list.new-analysis')}
            </div>
            <Button tertiary onClick={() => {}} className="p-2 hover:bg-day-light-3 rounded-full">
              <LiaPlusSquare size={24} className="transition-transform" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
