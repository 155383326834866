import { Label, TextInput, Button } from '@consigli/facade';
import { useProjects } from '@consigli/hooks';
import { type FC, useCallback } from 'react'; //Add userImperativeHandle as import when uncommenting code
import { UseFormRegister, FieldErrors, Control, UseFormReturn } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Breadcrumb } from '@/components/layouts/breadcrumb/breadcrumb';
import { Route } from '@/routes';
import { ProjectInputs } from '@/util/types';

import { ConstructionTypes } from './fields/construction-types';
import { ProjectCountry } from './fields/project-country';
import { ProjectType } from './fields/project-type';

type ProjectProps = {
  register: UseFormRegister<ProjectInputs>;
  errors: FieldErrors<ProjectInputs>;
  control: Control<ProjectInputs>;
  getValues: UseFormReturn<ProjectInputs>['getValues'];
  isLoading: boolean;
};

export const ProjectDetails: FC<ProjectProps> = ({
  register,
  errors,
  control,
  // getValues,
  isLoading,
}) => {
  const { projects } = useProjects({ page: 'all' });
  const { t } = useTranslation();

  const validateName = useCallback(
    (value: string) => {
      if (projects.find((p) => p.name === value)) return t('project-creation.project-name-taken');
    },
    [projects, t],
  );

  return (
    <>
      <Breadcrumb label={t('project-creation.new-project')} to={`/${Route.NEW_PROJECT}`} />
      <div className="flex flex-col gap-4 mt-1">
        <Label text={t('project-creation.project-name-label')}>
          <TextInput
            {...register('name', {
              required: `${t('project-creation.project-name-required')}*`,
              validate: validateName,
            })}
            className="border border-day-light-2"
          />
          {errors.name && (
            <div className={'text-sm my-1 text-error-dark'}>{errors.name.message}</div>
          )}
        </Label>

        <Label text={t('project-creation.project-address-label')}>
          <TextInput {...register('address')} className="border border-day-light-2" />
        </Label>

        <ProjectCountry control={control} />
        <ProjectType control={control} />
        <ConstructionTypes control={control} errors={errors} />
        {/* <Specifications
          control={control}
          errors={errors}
          initialValue={getValues('specifications')}
        /> */}
        <div className="my-4">
          <Button primary rounded className="w-full" type="submit" disabled={isLoading}>
            {t('project-creation.create-project')}
          </Button>
        </div>
      </div>
    </>
  );
};
