import { Button } from '@consigli/facade';
import { useServiceName, useProjectId, useServiceId, useServiceCategory } from '@consigli/hooks';
import { ServiceCategory, ServiceName } from '@consigli/types';
import { type FC } from 'react';
import { useTranslation } from 'react-i18next';
import { HiOutlineChatAlt2 } from 'react-icons/hi';
import { useNavigate } from 'react-router';

import { DocumentPackage } from '@/organisms/workspace-tabs/document-package';
import { CeilingGridPackage } from '@/pages/ceiling-grid/ceiling-grid-package';
import { LayoutPackage } from '@/pages/engineering/layout-package';
import { StructuralPackage } from '@/pages/structural/structural-package';
import { Route } from '@/routes';
import { TabConfig } from '@/util/types';

import { WorkspaceTabsItem } from './workspace-tabs-item';

const INSIGHT_TABS: TabConfig[] = [
  {
    href: (serviceName, projectId, serviceId) =>
      `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${serviceId}/${Route.DASHBOARD}`,
    name: 'servicetabs.overview',
  },
  {
    href: (serviceName, projectId, serviceId) =>
      `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${serviceId}/${Route.FINDINGS}`,
    name: 'servicetabs.riskassessment',
  },
  {
    href: (serviceName, projectId, serviceId) =>
      `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${serviceId}/${Route.DOCUMENTS}`,
    name: 'servicetabs.documents',
  },
  {
    href: (serviceName, projectId, serviceId) =>
      `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${serviceId}/${Route.CATEGORIZATION}`,
    name: 'servicetabs.folders',
  },
];

const LAYOUT_TABS: TabConfig[] = [
  {
    href: (serviceName, projectId, layoutId) =>
      `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${layoutId}/${Route.INPUT}`,
    name: 'optimization-tabs.input',
  },
  {
    href: (serviceName, projectId, layoutId) =>
      `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${layoutId}/${Route.RESULTS}`,
    name: 'optimization-tabs.results',
  },
];

const STRUCTURAL_TABS: TabConfig[] = [
  {
    href: (serviceName, projectId, serviceId) =>
      `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${serviceId}/${Route.INPUT}`,
    name: 'structural-tabs.input',
  },
  {
    href: (serviceName, projectId, serviceId) =>
      `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${serviceId}/${Route.RESULTS}`,
    name: 'structural-tabs.results',
  },
];

const CEILING_GRID_TABS: TabConfig[] = [
  {
    href: (serviceName, projectId, ceilingGridId) =>
      `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${ceilingGridId}/${Route.INPUT}`,
    name: 'optimization-tabs.input',
  },
  {
    href: (serviceName, projectId, ceilingGridId) =>
      `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${ceilingGridId}/${Route.RESULTS}`,
    name: 'optimization-tabs.results',
  },
];

interface WorkspaceTabsProps {
  hideTabs?: boolean;
}

export const WorkspaceTabs: FC<WorkspaceTabsProps> = ({ hideTabs }) => {
  const serviceName = useServiceName();
  const projectId = useProjectId();
  const serviceId = useServiceId();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const serviceCategory = useServiceCategory();

  const filterTabs = (serviceName: string, tabs: TabConfig[]) => {
    return serviceName === ServiceName.MANAGEMENT_OPERATIONS_MAINTENANCE
      ? tabs
      : tabs.filter((obj) => obj.name !== 'servicetabs.folders');
  };

  const tabNames = (category: ServiceCategory) => {
    switch (category) {
      case ServiceCategory.INSIGHT:
        return filterTabs(serviceName, INSIGHT_TABS);
      case ServiceCategory.LAYOUT_OPTIMIZATION:
        return LAYOUT_TABS;
      case ServiceCategory.STRUCTURAL_ENGINEERING:
        return STRUCTURAL_TABS;
      case ServiceCategory.CEILING_GRID:
        return CEILING_GRID_TABS;
    }
  };

  const renderScrollList = (category: ServiceCategory) => {
    switch (category) {
      case ServiceCategory.INSIGHT:
        return <DocumentPackage />;
      case ServiceCategory.LAYOUT_OPTIMIZATION:
        return <LayoutPackage />;
      case ServiceCategory.STRUCTURAL_ENGINEERING:
        return <StructuralPackage />;
      case ServiceCategory.CEILING_GRID:
        return <CeilingGridPackage />;
      default:
        return null;
    }
  };

  return (
    <div className="w-full">
      <div className="flex items-center justify-center max-w-screen-2xl mx-auto relative pb-4 px-8">
        <div className="absolute left-8">{renderScrollList(serviceCategory)}</div>
        <nav className="">
          {!hideTabs && (
            <div className="flex">
              {tabNames(serviceCategory).map(({ href, name }, index) => (
                <div
                  key={name}
                  className={`${index !== tabNames(serviceCategory).length - 1 ? 'mr-1' : ''}`}
                >
                  <WorkspaceTabsItem name={name} href={href(serviceName, projectId, serviceId)} />
                </div>
              ))}
            </div>
          )}
        </nav>
      </div>
      <div className="fixed bottom-20 right-20 z-[20]">
        {!hideTabs && serviceCategory === ServiceCategory.INSIGHT && (
          <Button
            primary
            icon={HiOutlineChatAlt2}
            iconSize={26}
            iconColor={'white'}
            className="rounded-full bg-day-dark-1 h-[42px]"
            onClick={() =>
              navigate(
                `/${Route.PROJECTS}/${projectId}/${Route.SERVICES}/${serviceName}/${Route.PACKAGES}/${serviceId}/${Route.PROPCHAT}`,
              )
            }
          >
            {t('servicetabs.propchat')}
          </Button>
        )}
      </div>
    </div>
  );
};
