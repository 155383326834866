import clsx from 'clsx';
import { type FC } from 'react';

type DashboardCardProps = {
  title: string;
  body: number | string;
  className?: string;
  onClick?: () => void;
};

/**
 * @returns Link element if there is a url defined which the card links to.
 * If additional logic to navigate is required it returns a button element with an onClick function.
 */
export const DashboardCard: FC<DashboardCardProps> = ({ title, body, className, onClick }) => {
  return (
    <div
      className={clsx(
        'relative overflow-hidden flex items-start w-full h-24 p-2 shadow-[0_1px_2px_0_rgba(0,0,0,0.06),0_1px_3px_0_rgba(0,0,0,0.10)] border-2 border-day-light-5 rounded-xl before:absolute before:top-0 before:left-0 before:h-full before:bg-day-light-3 before:w-6 hover:before:w-full before:transition-width before:duration-300 before:ease before:content-[""] group',
        className,
      )}
      onClick={onClick}
      aria-hidden
    >
      <div className="flex flex-col items-start grow h-full px-2 pt-1 truncate ml-6 transition-width duration-300 ease group-hover:ml-6 transition-transform group-hover:translate-x-[-20px]">
        <div className="truncate text-xl w-full text-start text-day-neutral-dark" title={title}>
          {title}
        </div>
        <div className="font-semibold text-2xl pt-1 text-day-neutral-dark">{body}</div>
      </div>
    </div>
  );
};
