import { Button, Search, Spinner } from '@consigli/facade';
import { useDownloadFilesAsZip, useProject, useProjectId } from '@consigli/hooks';
import { Document } from '@consigli/types';
import clsx from 'clsx';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa6';
import { HiOutlineCloudDownload } from 'react-icons/hi';

import Drawing from '@/assets/images/layout/drawing.png';
import IfcFiles from '@/assets/images/layout/ifc-files.png';
import Reports from '@/assets/images/layout/reports.png';
import VentillationZoneDrawings from '@/assets/images/layout/ventillation-zone-drawings.png';
import { DownloadFileButton } from '@/components/common/download-file-button';
import { useViewerContext, ViewerMode } from '@/contexts/use-viewer-context';
import { useViewer } from '@/hooks/use-viewer';
import { isIFCDocumentType } from '@/util/document-check';

import { DocumentFullscreen } from './document-fullscreen';
import { DocumentPreview } from './document-preview';

interface EngineeringResultsProps {
  files: Document[];
  isFetching: boolean;
}

type DocumentType = 'IFC' | 'VENTILATION_ZONE_DRAWINGS' | 'DRAWINGS_2D' | 'REPORTS' | 'OTHER';

interface GroupedRecords {
  drawing: Document[];
  ifc: Document[];
  report: Document[];
  ventilation: Document[];
  other: Document[];
}

export default function EngineeringResults({ files, isFetching }: EngineeringResultsProps) {
  const { t } = useTranslation();
  const projectId = useProjectId();
  const { project } = useProject(projectId);
  const [pageRecords, setPageRecords] = useState<Document[]>([]);
  const [searchText, setSearchText] = useState('');
  const { openDocumentViewer } = useViewer();
  const { mode } = useViewerContext();

  // Track which section is currently downloading
  const [downloadingSection, setDownloadingSection] = useState<DocumentType | null>(null);

  // Create refs for all scrollable containers
  const drawingContainerRef = useRef<HTMLDivElement>(null);
  const reportsContainerRef = useRef<HTMLDivElement>(null);
  const ifcContainerRef = useRef<HTMLDivElement>(null);
  const ventilationContainerRef = useRef<HTMLDivElement>(null);
  const otherContainerRef = useRef<HTMLDivElement>(null);

  const { downloadFileZip: downloadSelected, isDownloading: isDownloadingSelected } =
    useDownloadFilesAsZip(project?.name ?? 'files');

  const [showReportsScrollButtons, setShowReportsScrollButtons] = useState(false);
  const [showIfcScrollButtons, setShowIfcScrollButtons] = useState(false);
  const [showVentillationScrollButtons, setShowVentillationScrollButtons] = useState(false);
  const [showDrawingsScrollButtons, setShowDrawingsScrollButtons] = useState(false);
  const [showOthersScrollButtons, setShowOthersScrollButtons] = useState(false);

  const onClickDownloadSelected = useCallback(
    async (records: Document[], sectionType: DocumentType) => {
      setDownloadingSection(sectionType);
      await downloadSelected(records);
      setDownloadingSection(null);
    },
    [downloadSelected],
  );

  const handleSearch = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  }, []);

  useEffect(() => {
    setPageRecords(files);
  }, [files]);

  const filteredRecords = useMemo(() => {
    return pageRecords.filter((record) =>
      record.name.toLowerCase().includes(searchText.toLowerCase()),
    );
  }, [pageRecords, searchText]);

  const groupedRecords = useMemo(() => {
    const initialValue: GroupedRecords = {
      drawing: [],
      ifc: [],
      report: [],
      ventilation: [],
      other: [],
    };

    return filteredRecords.reduce((acc, record) => {
      if (record.fileType === 'DRAWINGS_2D') {
        acc.drawing.push(record);
      } else if (record.fileType === 'IFC') {
        acc.ifc.push(record);
      } else if (record.fileType === 'REPORTS') {
        acc.report.push(record);
      } else if (record.fileType === 'VENTILATION_ZONE_DRAWINGS') {
        acc.ventilation.push(record);
      } else {
        acc.other.push(record);
      }
      return acc;
    }, initialValue);
  }, [filteredRecords]);

  const getOnClickHandler = useCallback(
    (record: Document) => {
      return () => {
        if (isIFCDocumentType(record.extension)) {
          openDocumentViewer(record, ViewerMode.DocumentFullscreen);
        } else {
          openDocumentViewer(record, ViewerMode.DocumentPreview);
        }
      };
    },
    [openDocumentViewer],
  );

  const renderDocumentCard = (record: Document, imageSource: string) => (
    <div
      className="flex items-center py-4"
      key={record.id}
      onClick={getOnClickHandler(record)}
      aria-hidden="true"
    >
      <div className="w-40 sm:w-44 md:w-48 lg:w-52 h-56 md:h-64 bg-white shadow-[0px_0px_20px_0px_#0000001A] p-4 rounded-lg cursor-pointer transition-shadow duration-300 hover:shadow-[0px_0px_20px_0px_#00000033]">
        <div>
          <img className="h-40 md:h-48 w-full object-contain" src={imageSource} alt={record.name} />
        </div>
        <div className="flex justify-between items-center">
          <div
            className="max-w-[calc(100%-45px)] truncate overflow-hidden text-ellipsis whitespace-nowrap"
            title={record.name}
          >
            {record.name}
          </div>
          <DownloadFileButton document={record} className="rounded-full" size="20" />
        </div>
      </div>
    </div>
  );

  const scrollContainer = (
    containerRef: React.RefObject<HTMLDivElement>,
    direction: 'left' | 'right',
  ) => {
    if (containerRef.current) {
      // Calculate scrollAmount based on container width for more natural feel
      const containerWidth = containerRef.current.clientWidth;

      // Calculate the currently visible items
      const visibleItemWidth = 240; // Approximate width of each card + gap
      const visibleItems = Math.floor(containerWidth / visibleItemWidth);

      // Find the scroll position of the next/previous set of items
      const currentPosition = containerRef.current.scrollLeft;
      let targetPosition;

      if (direction === 'left') {
        // Scroll to previous page of items
        targetPosition = Math.max(0, currentPosition - visibleItems * visibleItemWidth);
      } else {
        // Scroll to next page of items
        targetPosition = currentPosition + visibleItems * visibleItemWidth;
      }

      // Use scrollTo instead of scrollBy for more precise control
      containerRef.current.scrollTo({
        left: targetPosition,
        behavior: 'smooth',
      });
    }
  };

  // Check if the content is overflowing for each container
  const checkOverflow = useCallback(
    (
      containerRef: React.RefObject<HTMLDivElement>,
      setShowScrollButtons: React.Dispatch<React.SetStateAction<boolean>>,
    ) => {
      if (containerRef.current) {
        const hasOverflow = containerRef.current.scrollWidth > containerRef.current.clientWidth;
        setShowScrollButtons(hasOverflow);
      }
    },
    [],
  );

  useEffect(() => {
    const checkReportsOverflow = () =>
      checkOverflow(reportsContainerRef, setShowReportsScrollButtons);
    const checkIfcOverflow = () => checkOverflow(ifcContainerRef, setShowIfcScrollButtons);
    const checkVentillationOverflow = () =>
      checkOverflow(ventilationContainerRef, setShowVentillationScrollButtons);
    const checkDrawingsOverflow = () =>
      checkOverflow(drawingContainerRef, setShowDrawingsScrollButtons);
    const checkOthersOverflow = () => checkOverflow(otherContainerRef, setShowOthersScrollButtons);

    checkReportsOverflow();
    checkIfcOverflow();
    checkVentillationOverflow();
    checkDrawingsOverflow();
    checkOthersOverflow();

    window.addEventListener('resize', checkReportsOverflow);
    window.addEventListener('resize', checkIfcOverflow);
    window.addEventListener('resize', checkVentillationOverflow);
    window.addEventListener('resize', checkDrawingsOverflow);
    window.addEventListener('resize', checkOthersOverflow);

    return () => {
      window.removeEventListener('resize', checkReportsOverflow);
      window.removeEventListener('resize', checkIfcOverflow);
    };
  }, [
    checkOverflow,
    groupedRecords.ifc,
    groupedRecords.drawing,
    groupedRecords.report,
    groupedRecords.ventilation,
    groupedRecords.other,
  ]);

  const renderSection = (
    title: string,
    description: string,
    records: Document[],
    containerRef: React.RefObject<HTMLDivElement>,
    showScrollButtons: boolean,
    image: string,
    sectionType: DocumentType,
  ) => {
    if (records.length === 0) {
      return null;
    }

    const isDownloading = downloadingSection === sectionType;
    return (
      <div className="mb-8">
        <div className="flex flex-col sm:flex-row items-start sm:items-center justify-between mb-4">
          <div className="flex items-center gap-x-4">
            <h3>{title}</h3>
            <div className="mx-4 text-night-light-5 font-normal text-sm">{description}</div>
          </div>
          <div>
            <button
              disabled={
                (downloadingSection !== null && downloadingSection !== sectionType) ||
                isDownloadingSelected
              }
              onClick={() => onClickDownloadSelected(records, sectionType)}
              className={clsx(
                'hover:bg-day-light-3 rounded-full p-2 flex items-center justify-center',
                'w-10 h-10',
                isDownloading && 'transition-colors duration-200',
              )}
              aria-label={isDownloading ? 'Downloading...' : 'Download files'}
            >
              {isDownloading ? <Spinner size="xsmall" /> : <HiOutlineCloudDownload size={20} />}
            </button>
          </div>
        </div>
        <div className="relative w-full">
          {showScrollButtons && (
            <Button
              tertiary
              rounded
              className="font-light hover:bg-day-light-3 hover:text-night-dark-1 focus:bg-[#33415587] focus:text-white  absolute left-0 top-1/2 -translate-y-1/2 z-10 bg-[#33415587] rounded-full p-2 shadow-md text-white"
              onClick={() => scrollContainer(containerRef, 'left')}
            >
              <FaChevronLeft />
            </Button>
          )}
          <div
            ref={containerRef}
            className="pl-4 flex items-center justify-start gap-x-8 overflow-x-auto pb-4 no-scrollbar scroll-smooth pr-4"
          >
            {records.map((record) => renderDocumentCard(record, image))}
          </div>
          {showScrollButtons && (
            <Button
              tertiary
              rounded
              className="font-light hover:bg-day-light-3 hover:text-night-dark-1 focus:bg-[#33415587] focus:text-white absolute right-0 top-1/2 -translate-y-1/2 z-10 bg-[#33415587] rounded-full p-2 shadow-md text-white"
              onClick={() => scrollContainer(containerRef, 'right')}
            >
              <FaChevronRight />
            </Button>
          )}
        </div>
      </div>
    );
  };

  return (
    <div className="w-full h-full">
      <div className="px-4 sm:px-6 md:px-8 py-4 w-full">
        {isFetching ? (
          <Spinner />
        ) : pageRecords.length === 0 ? (
          <div className="bg-day-light-3 w-full h-full flex flex-col items-center justify-center">
            <div className="text-day-neutral-subtle text-xl font-medium py-4">
              {t('document-list.no-documents-match-filter')}
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-row justify-start items-center w-full pb-8">
              <Search
                text={searchText}
                onChange={handleSearch}
                placeholder={t('project-list.search-files')}
              />
            </div>

            {renderSection(
              t('optimization-tabs.ifc-files'),
              t('optimization-tabs.ifc-files-result-description'),
              groupedRecords.ifc,
              ifcContainerRef,
              showIfcScrollButtons,
              IfcFiles,
              'IFC',
            )}
            {renderSection(
              t('optimization-tabs.ventilation-zone-drawings'),
              t('optimization-tabs.ventilation-zone-description'),
              groupedRecords.ventilation,
              ventilationContainerRef,
              showVentillationScrollButtons,
              VentillationZoneDrawings,
              'VENTILATION_ZONE_DRAWINGS',
            )}
            {renderSection(
              t('optimization-tabs.2d-drawing'),
              t('optimization-tabs.2d-drawing-description'),
              groupedRecords.drawing,
              drawingContainerRef,
              showDrawingsScrollButtons,
              Drawing,
              'DRAWINGS_2D',
            )}

            {renderSection(
              t('optimization-tabs.reports'),
              t('optimization-tabs.reports-description'),
              groupedRecords.report,
              reportsContainerRef,
              showReportsScrollButtons,
              Reports,
              'REPORTS',
            )}

            {renderSection(
              t('optimization-tabs.others'),
              t('optimization-tabs.others-description'),
              groupedRecords.other,
              otherContainerRef,
              showOthersScrollButtons,
              Drawing,
              'OTHER',
            )}

            {mode === ViewerMode.DocumentPreview && <DocumentPreview />}
            {mode === ViewerMode.DocumentFullscreen && <DocumentFullscreen />}
          </>
        )}
      </div>
    </div>
  );
}
