import { Button } from '@consigli/facade';
import {
  useProjectId,
  usePackageId,
  useLocalState,
  useGetPackagePropChatStatusQuery,
  useCurrentLanguage,
} from '@consigli/hooks';
import { getSessionFromStorage } from '@consigli/oauth';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Popup } from 'reactjs-popup';

import { Breadcrumb } from '@/components/layouts/breadcrumb/breadcrumb';
import { WorkspaceLayout } from '@/components/layouts/workspace-layout/workspace-layout';
import { WorkspaceProvider } from '@/contexts/use-workspace-context';
import { Chat } from '@/organisms/chat';
import { type Message } from '@/util/interfaces';

export const PropChat = () => {
  const { t } = useTranslation();
  const projectId = useProjectId();
  const packageId = usePackageId();
  const language = useCurrentLanguage();
  const chatApiBaseUrl = import.meta.env.VITE_CHAT_API_BASE_URL;

  const [propChatDisclaimerShown, setPropChatDisclaimerShown] = useLocalState<boolean>(
    'prop-chat-disclaimer-shown',
    false,
  );

  const { data: status, isLoading: loadingStatus } = useGetPackagePropChatStatusQuery({
    projectId,
    packageId,
  });

  const token = getSessionFromStorage();
  const [loadingAnswer, setLoadingAnswer] = useState(false);
  const [messages, setMessages] = useState<Message[]>(() => {
    const storedMessages = sessionStorage.getItem(`propchat-${packageId}`);
    return storedMessages
      ? JSON.parse(storedMessages)
      : [
          {
            id: crypto.randomUUID(),
            body: t('prop-chat.welcome-message'),
            authorId: crypto.randomUUID(),
            createdAt: new Date(),
          },
        ];
  });
  const onMessage = useCallback(
    async (message: Message) => {
      setMessages((messages) => {
        const newMessages = [...messages, message];
        sessionStorage.setItem(`propchat-${packageId}`, JSON.stringify(newMessages));
        return newMessages;
      });

      setLoadingAnswer(true);

      try {
        const response = await fetch(
          `${chatApiBaseUrl}/projects/${projectId}/packages/${packageId}/query`,
          {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: token?.idToken.token ? `Bearer ${token.idToken.token}` : '',
            },
            body: JSON.stringify({ question: message.body, language }),
          },
        );

        if (!response.ok) {
          throw new Error(`Failed to fetch response: ${response.status}`);
        }

        const reader = response.body?.getReader();
        if (!reader) throw new Error('Streaming not supported');

        const decoder = new TextDecoder();
        let accumulatedText = '';
        let sources: { content: string; blobId: string }[] = [];
        let isFirstChunk = true;
        let buffer = ''; // Stores incoming data until we get a full JSON object

        // ✅ Ensure AI message is created before streaming starts
        const aiMessageId = crypto.randomUUID();
        setMessages((messages) => {
          const newMessages = [
            ...messages,
            {
              id: aiMessageId,
              authorId: 'ai',
              body: '',
              sources: [],
              createdAt: new Date(),
            },
          ];
          sessionStorage.setItem(`propchat-${packageId}`, JSON.stringify(newMessages)); // ✅ Save immediately
          return newMessages;
        });

        while (true) {
          const { value, done } = await reader.read();
          if (done) break;

          buffer += decoder.decode(value, { stream: true }); // Add new data to buffer

          // Process full JSON objects from the buffer
          try {
            const jsonObjects = buffer
              .split('\n') // Split into possible JSON objects
              .filter((line) => line.trim() !== '') // Remove empty lines
              .map((line) => JSON.parse(line)); // Parse JSON objects

            buffer = ''; // Reset buffer after processing

            for (const jsonChunk of jsonObjects) {
              if (isFirstChunk && jsonChunk.sources) {
                sources = jsonChunk.sources.map((s: { content: string; blobId: string }) => ({
                  content: s.content,
                  blobId: s.blobId || '',
                }));
                isFirstChunk = false;
                continue;
              }

              if (jsonChunk.answer) {
                for (const char of jsonChunk.answer) {
                  accumulatedText += char;

                  // ✅ Update AI message dynamically & save chat history
                  setMessages((messages) => {
                    const newMessages = messages.map((msg) =>
                      msg.id === aiMessageId
                        ? { ...msg, body: accumulatedText, sources } // ✅ Ensure sources are stored
                        : msg,
                    );

                    sessionStorage.setItem(`propchat-${packageId}`, JSON.stringify(newMessages)); // ✅ Persist AI response continuously
                    return newMessages;
                  });

                  // ✅ Smooth character-by-character streaming effect
                  await new Promise((resolve) => setTimeout(resolve, 10));
                }
              }
            }
          } catch (err) {
            console.warn('Waiting for full JSON chunk...');
          }
        }
      } catch (e) {
        console.error('Error fetching response:', e);
        setMessages((messages) => [
          ...messages,
          {
            id: crypto.randomUUID(),
            body: t('prop-chat.query-failed'),
            authorId: crypto.randomUUID(),
            createdAt: new Date(),
          },
        ]);
      } finally {
        setLoadingAnswer(false);
      }
    },
    [t, setMessages, projectId, packageId, token?.idToken.token, chatApiBaseUrl, language],
  );

  return (
    <WorkspaceProvider>
      <WorkspaceLayout>
        <Breadcrumb label={t('servicetabs.propchat')} />
        <Popup
          open={!propChatDisclaimerShown}
          onClose={() => setPropChatDisclaimerShown(true)}
          closeOnEscape={false}
          closeOnDocumentClick={false}
          contentStyle={{ maxWidth: 500 }}
        >
          <div className="p-4">
            <h1 className="text-xl pb-2 font-semibold">{t('prop-chat.disclaimer-header')}</h1>
            <p>{t('prop-chat.disclaimer-1')}</p>
            <br />
            <p>{t('prop-chat.disclaimer-2')}</p>
            <div className="flex flex-row justify-end pt-4">
              <Button primary onClick={() => setPropChatDisclaimerShown(true)}>
                {t('prop-chat.disclaimer-confirm')}
              </Button>
            </div>
          </div>
        </Popup>
        <Chat
          messages={messages}
          onMessage={onMessage}
          loading={loadingStatus}
          loadingMessage={loadingAnswer}
          disabled={status?.ready === false}
        />
      </WorkspaceLayout>
    </WorkspaceProvider>
  );
};
