import { Button } from '@consigli/facade';
import { useMessageContext } from '@consigli/hooks';
import { Finding } from '@consigli/types';
import { Highlight } from 'react-highlighter-ts';
import { useTranslation } from 'react-i18next';
import { FaRegComments } from 'react-icons/fa';
import { HiOutlineDocumentText, HiOutlineTag } from 'react-icons/hi';

import { Card } from '@/atoms/card';
import { UpdateFindingButtons } from '@/organisms/document-viewer/fullscreen/finding/update-finding';
import { CheckableFinding } from '@/util/types';

import { useFindingsContext } from './findings-context';

interface FindingsCardProps {
  finding: CheckableFinding;
  itemsOnPage: number;
  onCheckboxClick: (record: Finding) => void;
  selectedCard: boolean;
  onCardClick: () => void;
  onCommentsClick: (event: React.MouseEvent<HTMLElement>) => void;
}

export const FindingsCard = ({
  finding,
  itemsOnPage,
  selectedCard,
  onCardClick,
  onCommentsClick,
}: FindingsCardProps) => {
  const { t } = useTranslation();
  const { searchTerm, showFindingIds } = useFindingsContext();
  const messageContext = useMessageContext(finding);

  return (
    <>
      <Card selected={selectedCard} onClick={onCardClick}>
        <div className="flex flex-col md:flex-row gap-3 items-start">
          <UpdateFindingButtons
            className="flex-row md:flex-col justify-center"
            finding={finding}
            itemsOnPage={itemsOnPage}
          />
          <div className="flex flex-col flex-1">
            <div className="text-xs first-letter:uppercase">
              {t(`dashboard.conflict-title.${finding.typeOfConflict}`)}
            </div>
            <div className="font-semibold py-2">
              {/* @ts-expect-error - As event handlers which don't exist for React are required */}
              <Highlight placeholder="" search={searchTerm}>
                <p>
                  {t(finding.messageTemplate, {
                    ...messageContext,
                  })}
                </p>
              </Highlight>
            </div>
            <div className="flex gap-1">
              <HiOutlineDocumentText fontSize={16}></HiOutlineDocumentText>
              {/* @ts-expect-error - As event handlers which don't exist for React are required */}
              <Highlight placeholder="" search={searchTerm}>
                <p className="text-xs xl:max-w-xl md:max-w-80 max-w-sm truncate">
                  {finding.fileName ?? t('riskassessment.project-related-finding')}
                </p>
              </Highlight>
            </div>
            {finding.refFileName && (
              <div className="text-xs flex items-center mt-2">
                <HiOutlineDocumentText fontSize={16} className="mr-1"></HiOutlineDocumentText>
                <span className="text-xs">
                  <p>{finding.refFileName}</p>
                </span>
              </div>
            )}
            {showFindingIds && (
              <div className="flex mt-2">
                <HiOutlineTag />
                <p className="ml-1 text-xs">{finding.id}</p>
              </div>
            )}
          </div>
          <div className="flex flex-col flex-1">
            <div>
              <div className="text-xs pb-2"> {t('riskassessment.extract')}: </div>
              <div className="line-clamp-3 break-all">
                {/* @ts-expect-error - As event handlers which don't exist for React are required */}
                <Highlight placeholder="" search={searchTerm}>
                  <q>{finding.content ?? t('riskassessment.no-document-reference')}</q>
                </Highlight>
              </div>
            </div>
            {finding.refContent && (
              <div className="mt-2">
                <div className="text-xs pb-2"> {t('riskassessment.extract')}: </div>
                <div className="line-clamp-3 break-all">
                  <q>{finding.refContent}</q>
                </div>
              </div>
            )}
          </div>
          <div>
            <Button
              secondary
              rounded
              onClick={onCommentsClick}
              className="px-2 py-1"
              icon={FaRegComments}
            >
              <span className="font-normal">{`(${finding.comments.length})`}</span>
            </Button>
          </div>
        </div>
      </Card>
    </>
  );
};
