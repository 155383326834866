import { LoadingArea } from '@consigli/facade';
import { useProjectId, useProject, useMyUser, useOrganization } from '@consigli/hooks';
import {
  CeilingType,
  LayoutType,
  OrganizationType,
  ProjectAccess,
  ProjectRole,
  ServiceId,
  ServiceName,
  ServiceNameType,
  ServicePermissionName,
  // StructuralType,
} from '@consigli/types';
import React, { type FC, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';

import FloorPlanIcon from '@/assets/images/services/floor-plan-icon.png';
import { CardGrid } from '@/layouts/card-grid';
import { CenteredLayout } from '@/layouts/centered-content';
import { FluidLayout } from '@/layouts/fluid-content';
import { Nav } from '@/layouts/nav';
import { ServiceCard } from '@/organisms/service-card';
import { Route } from '@/routes';
import {
  contractorServicesOrder,
  developerServicesOrder,
  serviceConfig,
  superUserDeveloperServicesOrder,
} from '@/util/service-config';

type ServiceData = {
  serviceName: ServiceNameType;
  servicePermissionName?: ServicePermissionName;
  active?: boolean;
  icon: string;
  tooltip?: string;
  title: string;
  newServiceRoute: string;
  serviceRoute: string;
  serviceId: ServiceId | LayoutType | CeilingType; // StructuralType;
};

export const ServiceListPage: FC = () => {
  const projectId = useProjectId();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { project, isLoading } = useProject(projectId);
  const { user } = useMyUser();

  const { organization } = useOrganization(project?.organizationId || '');

  const generateServiceData = useCallback(
    (order: string[]): ServiceData[] => {
      const availableServices = project?.availableServices || [];
      const filteredServices = serviceConfig.filter((service) =>
        availableServices.includes(service.serviceName),
      );

      const services: ServiceData[] = filteredServices.map((service) => ({
        serviceName: service.serviceName,
        servicePermissionName: service.servicePermissionName,
        active: project?.activeServices?.includes(service.serviceType),
        icon: service.icon,
        tooltip: t(service.tooltipKey),
        title: t(service.titleKey),
        newServiceRoute: service.newServiceRoute,
        serviceRoute: service.serviceRoute,
        serviceId: service.serviceId,
      }));

      // Add conditional services for superusers
      if (user.isSuperuser) {
        services.push({
          serviceName: ServiceNameType.CEILING_GRID,
          servicePermissionName: ServicePermissionName.USE_CEILING_GRID_SERVICE,
          active: project?.activeServices?.includes(ServiceName.CEILING_GRID),
          icon: FloorPlanIcon,
          tooltip: t('service-tooltips.ceiling'),
          title: t('services.ceiling-grid'),
          newServiceRoute: `${Route.CEILING_GRID}/${Route.CREATE}`,
          serviceRoute: Route.CEILING_GRID,
          serviceId: CeilingType.CEILING_GRID,
        });
      }
      return services.sort((a, b) => order.indexOf(a.serviceName) - order.indexOf(b.serviceName));
    },
    [project?.activeServices, project?.availableServices, t, user.isSuperuser],
  );

  const developerOrder = useMemo(
    () =>
      generateServiceData(
        user.isSuperuser ? superUserDeveloperServicesOrder : developerServicesOrder,
      ),
    [generateServiceData, user.isSuperuser],
  );

  // due diligence is irrelevant for contractors
  const contractorOrder = useMemo(
    () =>
      generateServiceData(
        user.isSuperuser ? superUserDeveloperServicesOrder : contractorServicesOrder,
      ),
    [generateServiceData, user.isSuperuser],
  );

  const mapServicesToServiceCards = (listOfServices: ServiceData[]) => {
    return listOfServices.map((service) => {
      return (
        <React.Fragment key={service.serviceName}>
          <ServiceCard
            id={service.serviceName}
            title={service.title}
            icon={service.icon}
            active={service.active}
            tooltip={service.tooltip}
            serviceRoute={service.serviceRoute}
            serviceId={service.serviceId}
            projectId={projectId}
            newServiceRoute={service.newServiceRoute}
          />
        </React.Fragment>
      );
    });
  };

  const filteredServices: ServiceData[] = useMemo(() => {
    let services;
    if (
      organization?.type === OrganizationType.CONTRACTOR ||
      organization?.type === OrganizationType.SUBCONTRACTOR
    ) {
      services = contractorOrder;
    } else {
      services = developerOrder;
    }
    if (user.isSuperuser || user.isOrgAdmin) {
      return services;
    }
    const currentProjectAccess = user.projectAccess.find(
      (access: ProjectAccess) => access.id === projectId,
    );
    if (currentProjectAccess) {
      if (currentProjectAccess.role === ProjectRole.ADMIN) {
        return services;
      }
      if (currentProjectAccess.role === ProjectRole.USER) {
        const availableServices: ServiceData[] = [];
        availableServices.push(
          ...services.filter(
            (service) =>
              service.servicePermissionName &&
              currentProjectAccess.services.includes(service.servicePermissionName),
          ),
        );
        return availableServices;
      }
    }
    return [];
  }, [
    projectId,
    user.isOrgAdmin,
    user.isSuperuser,
    user.projectAccess,
    contractorOrder,
    developerOrder,
    organization?.type,
  ]);

  const hasActiveServices = filteredServices.filter((service) => service.active).length !== 0;
  const hasNoActiveServices = filteredServices.filter((service) => !service.active).length === 0;
  const onBackButtonClick = () => {
    navigate(`/${Route.PROJECTS}`);
  };
  return (
    <FluidLayout>
      <Nav handleClick={onBackButtonClick} />
      {isLoading ? (
        <LoadingArea title={t('service-list.loadingtitle')} className="pt-24" />
      ) : (
        <>
          <CenteredLayout>
            <div className="flex flex-col lg:flex-row w-full px-14 pt-8">
              <span className="text-[2rem] font-semibold capitalize text-day-neutral-dark">
                {project?.name}
              </span>
            </div>
            {hasActiveServices && (
              <div className="px-14 pb-8">
                <div className="text-base py-4 text-day-neutral-dark font-semibold">
                  {t('service-list.your_services')}
                </div>
                <CardGrid>
                  {mapServicesToServiceCards(filteredServices.filter((service) => service.active))}
                </CardGrid>
              </div>
            )}
          </CenteredLayout>
          {!hasNoActiveServices && (
            <div className="bg-day-light-3 flex-1">
              <CenteredLayout>
                <div className="px-14 pt-8 pb-8">
                  <div className="text-base py-4 text-day-neutral-dark font-semibold">
                    {t('service-list.add_service')}
                  </div>
                  <CardGrid>
                    {mapServicesToServiceCards(
                      filteredServices.filter((service) => !service.active),
                    )}
                  </CardGrid>
                </div>
              </CenteredLayout>
            </div>
          )}
        </>
      )}
    </FluidLayout>
  );
};
