import { Checkbox, Button, Position, Spinner, Pagination } from '@consigli/facade';
import { useProjectId, useSelectedCard } from '@consigli/hooks';
import { Finding, FindingCommenter, useConvertFindingToDocument } from '@consigli/types';
import clsx from 'clsx';
import { RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FaTimes } from 'react-icons/fa';
import { HiOutlineAdjustments } from 'react-icons/hi';

import { ViewerMode } from '@/contexts/use-viewer-context';
import { useViewer } from '@/hooks/use-viewer';
import { BatchUpdateButton } from '@/modules/pages/risk-assessment/batch-update-button';
import { FindingsCard } from '@/modules/pages/risk-assessment/findings-card';
import { mapActionNameToStates } from '@/modules/pages/risk-assessment/findings-content';
import { Tab } from '@/organisms/tabs/tab';
import { isValidDocumentType } from '@/util/document-check';
import { CheckableFinding } from '@/util/types';

import { FindingBlob } from './findings';
import { useFindingsContext } from './findings-context';
import { FindingsFilter } from './findings-filter';
import { useReset } from './use-reset';

interface FindingsWrapperProps {
  findings: Finding[];
  findingsCount: number;
  uniqueBlobs: FindingBlob[];
  commenters: FindingCommenter[];
  actionTabs: string[];
  actionCount: Record<string, number>;
  itemsOnPage: number;
  isFetchingFindings: boolean;
  isFetchingActionCount: boolean;
}

export const processing = '3';

export const FindingsWrapper = ({
  findings,
  findingsCount,
  uniqueBlobs,
  commenters,
  actionTabs,
  actionCount,
  itemsOnPage,
  isFetchingFindings,
  isFetchingActionCount,
}: FindingsWrapperProps) => {
  const { t } = useTranslation();
  const {
    page,
    setPage,
    pageSize,
    setSearchTerm,
    selectedActionTab,
    setSelectedActionTab,
    setSelectedBlobIds,
    scrollPosition,
    setScrollPosition,
    containerRef,
    pageRecords,
    setPageRecords,
    setCurrentFinding,
    currentFinding,
  } = useFindingsContext();
  const { reset } = useReset();
  const convertFindingToDocument = useConvertFindingToDocument(t);
  const { setSelectedCard } = useSelectedCard('');
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [selectCheckedCount, setSelectCheckedCount] = useState(0);
  const [showButtons, setShowButtons] = useState(false);
  const { openDocumentViewer } = useViewer();
  const projectId = useProjectId();
  const [displayFilter, setDisplayFilter] = useState(true);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const handleScroll = useCallback(() => {
    if (containerRef.current) {
      const scrollTop = containerRef.current.scrollTop;
      if (scrollTop !== undefined) {
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
        timerRef.current = setTimeout(() => {
          setScrollPosition(scrollTop);
        }, 200);
      }
    }
  }, [containerRef, setScrollPosition]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTop = scrollPosition;
    }
  });

  useEffect(() => {
    if (!displayFilter) {
      setSearchTerm('');
      setSelectedBlobIds([]);
      setPage(1);
      setScrollPosition(0);
    }
  }, [displayFilter, setPage, setSearchTerm, setSelectedBlobIds, setScrollPosition]);

  const filteredButtonKeys = useMemo(
    () =>
      Object.keys(mapActionNameToStates).filter(
        (item: string) => item !== selectedActionTab && item !== processing,
      ),
    [selectedActionTab],
  );
  const resetSelectAll = useCallback(() => {
    setSelectAllChecked(false);
    setPageRecords((prevData: CheckableFinding[]) =>
      prevData.map(
        (item: CheckableFinding) =>
          item && { ...item, checked: item.checked ? false : item.checked },
      ),
    );
  }, [setPageRecords]);

  const handleTabClick = useCallback(
    (tab: string) => {
      setSelectedActionTab(tab);
      setPage(1);
      resetSelectAll();
      setScrollPosition(0);
    },
    [setSelectedActionTab, setPage, resetSelectAll, setScrollPosition],
  );
  const handlePageChange = useCallback(
    (page: number) => {
      reset(page);
    },
    [reset],
  );
  const handleCheckboxClick = (record: CheckableFinding) => {
    setPageRecords((prevData: CheckableFinding[]) =>
      prevData.map((item: CheckableFinding) =>
        item.id === record.id ? { ...item, checked: !item.checked } : item,
      ),
    );
  };
  const handleSelectAllCheckboxClick = () => {
    setSelectAllChecked(!selectAllChecked);
    setPageRecords((prevData: CheckableFinding[]) =>
      prevData.map((item: CheckableFinding) => item && { ...item, checked: !selectAllChecked }),
    );
  };
  const onComplete = () => {
    setShowButtons(false);
    setSelectAllChecked(false);
  };
  const onCommentsClick = useCallback(
    (record: CheckableFinding) => {
      return (event: React.MouseEvent<HTMLElement>) => {
        event.stopPropagation();
        setSelectedCard(record.id.toString());
        openDocumentViewer(convertFindingToDocument(record), ViewerMode.CommentPreview);
      };
    },
    [setSelectedCard, openDocumentViewer, convertFindingToDocument],
  );
  const getOnClickHandler = useCallback(
    (record: CheckableFinding) => {
      return () => {
        setCurrentFinding(record);
        setSelectedCard(record.id.toString());
        if (record.fileName != null && isValidDocumentType(record.fileName)) {
          openDocumentViewer(convertFindingToDocument(record), ViewerMode.DocumentPreview);
        }
      };
    },
    [setCurrentFinding, setSelectedCard, openDocumentViewer, convertFindingToDocument],
  );

  useEffect(() => {
    setPageRecords(findings as CheckableFinding[]);
  }, [findings, setPageRecords]);

  useEffect(() => {
    // showing buttons to change multiple status through popup
    const newCount = pageRecords?.reduce(
      (count: number, item: CheckableFinding) => count + (item.checked ? 1 : 0),
      0,
    );
    setSelectCheckedCount(newCount);
    setShowButtons(newCount > 1);
  }, [pageRecords]);

  return (
    <>
      <div className="relative">
        <div className="flex flex-col h-[calc(100vh-20rem)] xl:h-[calc(100vh-22rem)]">
          <div className="flex flex-col sm:flex-row justify-between items-center flex-wrap mt-0 xl:mt-4 mb-2">
            {isFetchingActionCount ? (
              <Spinner />
            ) : (
              <div className="flex flex-row flex-wrap">
                {actionTabs.map((tab: string) => (
                  <Tab
                    key={tab}
                    label={t(`riskassessment.status.${tab}`)}
                    selected={selectedActionTab === tab}
                    onClick={() => handleTabClick(tab)}
                    count={actionCount[tab]}
                    icon={mapActionNameToStates[tab].icon}
                  />
                ))}
              </div>
            )}
            <div className="pt-4 xl:pt-0 font-medium flex">
              <Button
                className="flex items-center cursor-pointer text-sm mr-2"
                aria-hidden
                onClick={() => setDisplayFilter((prevData) => !prevData)}
                icon={displayFilter ? FaTimes : HiOutlineAdjustments}
                iconPosition={Position.RIGHT}
                tertiary
                rounded
              >
                {displayFilter ? t('riskassessment.clear-filter') : t('riskassessment.add-filter')}
              </Button>
            </div>
          </div>
          <span
            className={clsx(
              'transition-opacity duration-500 mb-2',
              displayFilter ? 'opacity-100' : 'opacity-0',
            )}
          >
            {displayFilter && (
              <FindingsFilter
                selectAllChecked={selectAllChecked}
                handleSelectAllCheckboxClick={handleSelectAllCheckboxClick}
                uniqueBlobs={uniqueBlobs}
                commenters={commenters}
              />
            )}
          </span>
          {isFetchingFindings ? (
            <Spinner />
          ) : pageRecords.length === 0 ? (
            <div className="bg-day-light-3 w-full h-full flex flex-col items-center justify-center">
              <div className="text-day-neutral-subtle text-xl font-medium py-4">
                {t('riskassessment.no-findings-by-filter')}
              </div>
            </div>
          ) : (
            <div
              className="mb-4 overflow-auto"
              ref={containerRef as RefObject<HTMLInputElement>}
              onScroll={handleScroll}
            >
              {pageRecords?.map((record: CheckableFinding) => (
                <div key={record.id} className="flex items-center justify-between">
                  <div className="pl-1 pr-4">
                    <Checkbox
                      value={record.id.toString()}
                      label=""
                      id={record.id.toString()}
                      checked={record.checked}
                      onChange={() => handleCheckboxClick(record)}
                    />
                  </div>
                  <div className="flex-1">
                    <FindingsCard
                      finding={record}
                      itemsOnPage={itemsOnPage}
                      onCheckboxClick={() => handleCheckboxClick(record)}
                      onCardClick={getOnClickHandler(record)}
                      selectedCard={currentFinding?.id === record.id}
                      onCommentsClick={onCommentsClick(record)}
                    />
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="pt-2 xl:pt-4 flex flex-col md:flex-row justify-center items-center gap-8 text-day-neutral-dark">
          <div className="text-sm font-semibold">
            {t('riskassessment.showing_findings', { count: findingsCount })}
          </div>
          <Pagination
            currentPage={page}
            totalCount={findingsCount}
            pageSize={pageSize}
            onPageChange={handlePageChange}
          />
        </div>
        <BatchUpdateButton
          buttonKeys={filteredButtonKeys}
          projectId={projectId}
          count={selectCheckedCount}
          showButtons={showButtons}
          setShowButtons={setShowButtons}
          itemsOnPage={itemsOnPage}
          onComplete={onComplete}
        />
      </div>
    </>
  );
};
